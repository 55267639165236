





























import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import TaskState from '@/task-states/models/TaskState';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';
import {TorontoCHMB41HQuiz4Question1Part} from '@/tasks/components/dynamic-questions/TorontoCHMB41HQuiz4Question1.vue';

const parts: ('A' | 'B')[] = ['A', 'B'];
export type TorontoCHMB41HQuiz4Question2Part = (typeof parts)[number];
export type TorontoCHMB41HQuiz4Question2TaskState = TaskState<{
  partAVersion: VariableValueType.IntegerValue;
  partBVersion: VariableValueType.IntegerValue;
}>;

export default defineComponent({
  name: 'TorontoCHMB41HQuiz4Question2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<TorontoCHMB41HQuiz4Question2TaskState>,
      required: true,
    },
  },
  data() {
    return {
      partAttachments: {
        A: [],
        B: [],
      } as Record<TorontoCHMB41HQuiz4Question2Part, File[]>,
      imagePrefix: '/img/assignments/UofT/CHMB41H/quiz-4/question-2/q4q2',
      questionParts: parts,
    };
  },
  computed: {
    versions(): Partial<Record<TorontoCHMB41HQuiz4Question2Part, number>> {
      return parts.reduce(
        (acc: Partial<Record<TorontoCHMB41HQuiz4Question2Part, number>>, part) => {
          acc[part] = this.taskState.getValueByName(`part${part}Version`)?.content?.value ?? 1;
          return acc;
        },
        {}
      );
    },
    images(): Partial<Record<TorontoCHMB41HQuiz4Question1Part, string>> {
      return parts.reduce(
        (acc: Partial<Record<TorontoCHMB41HQuiz4Question1Part, string>>, part) => {
          acc[part] = `${this.imagePrefix}-part${part}-v${this.versions[part]}.png`;
          return acc;
        },
        {}
      );
    },
    attachments(): File[] {
      return [...this.partAttachments.A, ...this.partAttachments.B];
    },
    attachmentParts(): Record<string, string> {
      const partMap: Record<string, string> = {};

      for (const part of this.questionParts) {
        for (const attachment of this.partAttachments[part]) {
          partMap[attachment.name] = part;
        }
      }

      return partMap;
    },
  },
});
